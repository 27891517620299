import React, { FC, useState } from 'react'
import { Dialog } from '@headlessui/react'
import Form from '@components/main/Form'
import FormSuccess from '@components/main/FormSuccess'
import { useStore } from '@nanostores/react'
import { applicationFormOpen } from 'src/store'

const ModalForm: FC = () => {
  const modalOpen = useStore(applicationFormOpen)
  const [formDone, setFormDone] = useState(false)

  return (
    <Dialog className='fixed z-50 inset-0 overflow-y-auto flex items-end md:items-center justify-center' open={modalOpen} onClose={() => applicationFormOpen.set(false)}>
      <Dialog.Overlay className='bg-grayscale-0 bg-opacity-30 fixed inset-0 z-10' />
      <div className='bg-white-0 relative z-50 p-6 rounded-t-xl md:rounded-xl md:max-w-md'>
        {formDone
          ?
          <div>
            <FormSuccess />
            <button
              className='px-8 h-11 bg-red-100 hover:bg-red-50 active:bg-red-150 rounded-full text-white-0 text-p200 font-semibold leading-full w-full'
              onClick={() => applicationFormOpen.set(false)}
            >
              Закрыть
            </button>
          </div>
          :
          <div>
            <div className='flex justify-between items-center mb-5'>
              <Dialog.Title className='font-display text-h400 font-medium'>Оформление заявки</Dialog.Title>
              <button onClick={() => applicationFormOpen.set(false)} className='p-2 left-2 bottom-2 box-content relative' role='button'>
                <svg width='17' height='17' viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
                  <path d='M1 1L16 16' stroke='#A6A6A6' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
                  <path d='M16 1L1 16' stroke='#A6A6A6' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
                </svg>
              </button>
            </div>
            <Dialog.Description className='text-p350 text-grayscale-150 mb-6'>
              Мы с радостью ответим на ваши вопросы.
              Заполните форму и мы вам перезвоним.
            </Dialog.Description>
            <Form source='modal' onSuccess={() => setFormDone(true)} />
            <p className='text-p450 mt-6 text-grayscale-200'>Отправляя форму, вы даете <a href='/docs/personal-data-agreement.pdf' target='_blank' rel='noreferrer' className='text-red-100 hover:underline'>согласие на обработку персональных данных</a>, указанных вами в заявке, в соответствии с требованиями Федерального закона № 152-ФЗ от 27.07.2006 «О персональных данных».</p>
          </div>
        }

      </div>
    </Dialog>
  )
}

export default ModalForm