import React, { FC } from 'react'

const FormSuccess: FC = () => {
  return (
    <section className='text-center'>
      <svg className='mx-auto' width='84' height='84' viewBox='0 0 84 84' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M42 73.5C24.6015 73.5 10.5 59.3985 10.5 42C10.5 24.6015 24.6015 10.5 42 10.5C59.3985 10.5 73.5 24.6015 73.5 42C73.5 59.3985 59.3985 73.5 42 73.5Z' stroke='#EE2323' strokeWidth='3' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M56 35L38.5 52.5L28 42' stroke='#EE2323' strokeWidth='3' strokeLinecap='round' strokeLinejoin='round' />
      </svg>

      <h1 className='font-display text-h300 font-medium mb-3 mt-5'>Заявка успешно отправлена!</h1>
      <p className='text-p350 text-grayscale-150 mb-6'>
        В ближайшее время мы вам перезвоним и обсудим дальнейшие шаги.
      </p>
    </section>
  )
}

export default FormSuccess