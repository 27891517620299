import React, { FC, useState, useEffect, useRef } from 'react'
import { useForm, SubmitHandler, FieldError } from 'react-hook-form'
import api from '../../graphql/client'
import useThrottledState from '@src/hooks/useThrottled'
import type { CompanyData } from '../../types/dadata'
import { useWindowEvent } from '@hooks/useWindowEvent'
import usePatternMask from '@hooks/usePatternMask'
import useMutation from '@hooks/useMutation'
import { usePopper } from 'react-popper'
import useToggle from '@src/hooks/useToggle'
import useCompanySuggestions from '@hooks/useCompanySuggestions'
import LoadignIcon from '../../svg/loading.svg'
import { Transition } from '@headlessui/react'
import Input from '../ui/Input'
import c from 'clsx'

type Inputs = {
  name: string
  company: string
  email: string
  phone: string
}

const inputMap: Record<string, keyof Inputs> = {
  email: 'email',
  fio: 'name',
  inn: 'company',
  phone: 'phone'
}

interface FormProps {
  onSuccess?: () => void
  source?: string
}

const phoneMask = {
  mask: '+{7} (000) 000-00-00'
}

const SITE_CHANNEL = 'site'

const getErrorMessage = (err?: FieldError) => {
  if (!err) return

  if (err.message) return err.message
  switch (err.type) {
    case 'required':
      return 'Обязательное поле'
    case 'fromSuggested':
      return 'Выберите компанию из предложенных'
    default:
      return err.type
  }
}

const getCompanyName = (company?: CompanyData) => {
  if (company) {
    if (company.name?.short_with_opf) {
      return company.name.short_with_opf
    }
    if (company.name?.full_with_opf) {
      return company.name.full_with_opf
    }
  }
  return ''
}

const Form: FC<FormProps> = ({ onSuccess }) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
    setError
  } = useForm<Inputs>()

  const [addApplication, { loading, data, error }] = useMutation(
    api.addApplication
  )

  const [buttonLoading, toggleLoading] = useToggle()

  const done = !!data?.addApplication?.application?.id
  useEffect(() => {
    if (done && onSuccess) {
      onSuccess()
    }
  }, [done, onSuccess])

  useEffect(() => {
    if (error?.length) {
      error.forEach((e) => {
        e?.extensions?.violations?.forEach((v) => {
          if (inputMap[v.path]) {
            setError(inputMap[v.path], {
              type: 'validate',
              message: v.message
            })
          }
        })
      })
    }
  }, [error, setError])

  const companyQuery = watch('company')

  const [throttledCompanyQuery] = useThrottledState(companyQuery, 500)

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    // get utm_source from cookie
    const utm_source = document.cookie
      .split(';')
      .find((c) => c.trim().startsWith('utm_source='))
      ?.split('=')[1]

    addApplication({
      input: {
        fio: data.name,
        email: data.email,
        inn: company?.inn as string,
        phone: '+' + data.phone.replace(/\D/g, ''),
        source: '/api/sources/1',
        utmSource: utm_source,
        channel: SITE_CHANNEL
      }
    })
  }

  const suggestions = useCompanySuggestions(throttledCompanyQuery)
  const [company, setCompany] = useState<CompanyData>()
  const [suggestionsOpen, setSuggestionsOpen] = useState(false)

  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  )
  const [referenceElement, setReferenceElement] =
    useState<HTMLDivElement | null>(null)
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom',
    modifiers: [
      { name: 'preventOverflow', enabled: false },
      { name: 'flip', enabled: false },
      { name: 'offset', options: { offset: [0, 10] } }
    ]
  })

  // закрываем подсказки, когда пользователь кликает вне их
  useWindowEvent('mousedown', (event) => {
    if (popperElement?.contains(event.target as Node)) return
    if (referenceElement?.contains(event.target as Node)) return

    setSuggestionsOpen(false)
  })

  // при выборе компании устанавливаем её имя в поле компании
  useEffect(() => {
    if (!company) return
    setValue('company', getCompanyName(company), {
      shouldValidate: true
    })
  }, [company, setValue])

  // инпут для телефона использует маску, поэтому его ref нужно передавать в usePatternMask
  const { ref: phoneInputRef, ...phoneInputProps } = register('phone', {
    required: true,
    minLength: {
      value: 18,
      message: 'Введите номер телефона полностью'
    }
  })

  const phoneInputMaskRef = useRef<HTMLInputElement | null>(null)
  usePatternMask(phoneInputMaskRef, phoneMask)

  return (
    <section className=''>
      {/* <h1 className='font-display text-h300 font-medium mb-3'>Оформление заявки</h1>
            <p className='text-p350 text-grayscale-150 mb-6'>
                Мы с радостью ответим на ваши вопросы.
                Заполните форму и мы вам перезвоним.
            </p> */}
      <form onSubmit={handleSubmit(onSubmit)} className='text-p350 relative'>
        <div className='grid grid-cols-1 gap-y-2 mb-6'>
          <Input
            label='Ваше имя'
            type='text'
            placeholder='Иванов Иван'
            error={getErrorMessage(errors.name)}
            {...register('name', {
              required: true,
              maxLength: {
                value: 255,
                message: 'Не более 255 символов'
              },
              minLength: {
                value: 2,
                message: 'Не менее 2 символов'
              }
            })}
          />
          <div ref={setReferenceElement}>
            <Input
              label={
                suggestions === null
                  ? 'ИНН компании'
                  : 'Название компании, ИП или ИНН'
              }
              type='text'
              autoComplete='off'
              placeholder='ИП Иванов'
              error={getErrorMessage(errors.company)}
              {...register('company', {
                required: true,
                validate: {
                  // требуем выбора из предложенных вариантов только если апи подсказок работает
                  fromSuggested: (value) => {
                    return suggestions === null
                      ? true
                      : value === getCompanyName(company)
                  }
                }
              })}
              onFocus={() => setSuggestionsOpen(true)}
            ></Input>
          </div>
          <Input
            label='Ваш E-mail'
            type='text'
            autoComplete='email'
            inputMode='email'
            placeholder='mail@example.com'
            error={getErrorMessage(errors.email)}
            {...register('email', {
              pattern: {
                value:
                  /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                message: 'Некорректный E-mail'
              }
            })}
          />
          <Input
            label='Ваш контактный телефон'
            type='tel'
            autoComplete='tel'
            inputMode='numeric'
            placeholder='+7 (987) 654-32-10'
            error={getErrorMessage(errors.phone)}
            ref={(el) => {
              phoneInputMaskRef.current = el
              phoneInputRef(el)
            }}
            {...phoneInputProps}
          />
          {suggestions && !!suggestions.length && suggestionsOpen && (
            <div
              ref={setPopperElement}
              style={styles.popper}
              {...attributes.popper}
              className='absolute w-full max-h-66 flex z-50'
            >
              <div className='rounded-xl flex flex-col ring-1 ring-grayscale-400 pt-4 bg-white-0 shadow-lg text-grayscale-150 text-p450 max-h-full'>
                <h3 className='px-5 pb-2'>
                  Выберите вариант или продолжите ввод
                </h3>
                <ul className='overflow-y-auto pb-4 scrollbar-hide'>
                  {suggestions.map((s) => (
                    <li
                      key={s.inn}
                      onClick={() => {
                        if (!!s.state.liquidation_date) return
                        setCompany(s)
                        setSuggestionsOpen(false)
                      }}
                      className={c(
                        'hover:bg-grayscale-450 px-5 py-2',
                        !s.state.liquidation_date && 'cursor-pointer'
                      )}
                    >
                      {/* <div className='text-grayscale-0 mb-1'>ООО “<span className='text-red-100'>ФЕРА</span>”</div> */}
                      <div
                        className={c(
                          'text-grayscale-0 mb-1',
                          !!s.state.liquidation_date && 'line-through'
                        )}
                      >
                        {getCompanyName(s)}
                      </div>
                      <div
                        className={c(
                          'text-ellipsis overflow-hidden line-clamp-2',
                          !!s.state.liquidation_date && 'line-through'
                        )}
                      >
                        {s.inn} {s.address?.value}
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}
        </div>

        <button
          role='button'
          type='submit'
          className='px-8 h-11 bg-red-100 hover:bg-red-50 active:bg-red-150 rounded-full text-white-0 text-p200 font-semibold leading-full w-full overflow-hidden relative'
        >
          <Transition
            show={!loading}
            className='relative transition-all transform duration-300'
            enterFrom='-translate-x-1/2 opacity-0'
            enterTo='translate-x-0 opacity-100'
            leaveTo='translate-x-1/2 opacity-0'
          >
            Оформить заявку
          </Transition>
          <Transition
            show={loading}
            className='absolute transition-all transform inset-0 flex justify-center items-center duration-300'
            enterFrom='-translate-x-1/4 opacity-0'
            enterTo='translate-x-0 opacity-100'
            leaveTo='translate-x-1/4 opacity-0'
          >
            <LoadignIcon className='animate-spin' />
          </Transition>
        </button>
      </form>
    </section>
  )
}

export default Form
